import React from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsListItem from "../components/news-list-item"
import Pagination from "../components/pagination";

const NewsList = ({ pageContext, data, location }) => {
  const {
    breadcrumb: { crumbs },
    currentPage,
    numPages
  } = pageContext

  return (
    <Layout>
      <SEO
          title="Nos actualités"
          description="Retrouvez la liste de nos actualités."
      />
      <div id="news-list" className="container list-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel="Infos pratiques"
          />

          <h1 className="title is-1">Infos pratiques</h1>
        </section>

        <section className="section page-content">
          <div id="news-list" className="columns is-multiline">
            {data.allNodeArticle.edges.map(({ node }, index) => (
              <NewsListItem node={node} key={index} columnType={12} textLimit={200} cssTruncate={false} />
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </section>
      </div>
    </Layout>
  )
}

export default NewsList

export const query = graphql`
query articleListQuery($skip: Int!, $limit: Int!) {
  allNodeArticle(
    filter: {
      status: {eq: true},
      publication_lte_now: {eq: false}
    }, 
    sort: { fields: created, order: DESC }, 
    limit: $limit, 
    skip: $skip
  ) {
    edges {
      node {
        id
        title
        created(formatString: "DD MMMM YYYY", locale: "fr")
        path {
          alias
        }
        body {
          value
          summary
        }
        field_publication
      }
    }
  }
}
`
